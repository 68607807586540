<template>
  <b-card body-class="p-0" class="m-0">
    <app-button @click="showContactForm" class="flex-1-md mb-1" text="E-Posta Ekle" icon="PlusIcon" size="md" block />
    <b-alert variant="warning" :show="showAlert" fade>
      <div class="alert-body">
        <feather-icon class="mr-50" icon="InfoIcon" />
        <span>Henüz e-posta eklenmemiştir. </span>
      </div>
    </b-alert>
    <b-card v-for="item in emails" :key="item._id" body-class="p-1 d-flex align-items-center justify-content-between flex-wrap gap-10" class="border-secondary mb-50">
      <div class="d-flex align-items-center gap-10">
        <feather-icon size="20" icon="MailIcon" />
        <span class="fs-14">{{ item.email }}</span>
      </div>
      <div class="d-flex justify-content-end flex-1">
        <app-dropdown boundary="scrollParent">
          <b-dropdown-item @click="showContactForm(item)"> <feather-icon icon="EditIcon" class="mr-25" /> Güncelle </b-dropdown-item>
          <b-dropdown-item @click="deleteEmail(item)"> <feather-icon icon="TrashIcon" class="mr-25" /> Kaldır </b-dropdown-item>
        </app-dropdown>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import ContactEmailForm from "./ContactEmailForm.vue";
export default {
  data() {
    return {
      showAlert: false,
      emails: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  watch: {
    emails() {
      this.showAlert = Boolean(!this.emails.length);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      if (this.selectedCompany?.parasut_contact_id || this.item?.contact_id) {
        this.$emit("loading", true);
        this.$axios
          .get("/finance/contact-emails/all", { params: { contact_id: this.selectedCompany?.parasut_contact_id ?? this.item?.contact_id } })
          .then((result) => {
            this.emails = result.data;
            this.$emit("loading", false);
          })
          .catch((err) => {
            this.$emit("loading", false);
            console.log(err);
          });
      } else this.emails = [];
    },
    deleteEmail(item) {
      this.$confirm({ message: "E-posta silinecektir emin misiniz" }, () => {
        this.$axios
          .delete(`/finance/contact-emails/${item?._id}`)
          .then(() => {
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    showContactForm(item) {
      this.$showAppSidebar(item ? "Güncelle" : "E-Posta Ekle", ContactEmailForm, { updateItem: item ? { ...item } : null, callback: this.getData });
    },
  },
};
</script>

<style></style>
