<template>
  <b-card no-body class="mb-0 border">
    <b-card-header @click="updateVisible" class="cursor-pointer">
      <div class="d-flex align-items-center justify-content-between w-100">
        <h5 class="m-0">E-Posta</h5>
        <div>
          <b-spinner v-if="isLoading"></b-spinner>
          <feather-icon v-else size="22" :icon="!isVisible ? 'ChevronDownIcon' : 'ChevronUpIcon'"></feather-icon>
        </div>
      </div>
    </b-card-header>
    <b-collapse v-model="isVisible">
      <b-card-body class="p-50">
        <contact-email-list @loading="isLoading = $event" v-if="isVisible" />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import ContactEmailList from "./ContactEmailList.vue";
export default {
  components: { ContactEmailList },
  data() {
    return {
      isVisible: false,
      isLoading: false,
    };
  },
  methods: {
    updateVisible() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style></style>
