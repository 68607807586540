<template>
  <div>
    <b-card no-body class="mb-0 border">
      <b-card-header @click="updateVisible" class="cursor-pointer">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h5 class="m-0">Notlar</h5>
          <div>
            <feather-icon size="22" :icon="!isVisible ? 'ChevronDownIcon' : 'ChevronUpIcon'"></feather-icon>
          </div>
        </div>
      </b-card-header>
      <b-collapse v-model="isVisible">
        <b-card-body class="p-50">
          <contact-notes v-if="isVisible" />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import ContactNotes from "./ContactNotes.vue";
export default {
  components: { ContactNotes },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    updateVisible() {
      this.isVisible = !this.isVisible;
    },
  },
};
</script>

<style></style>
