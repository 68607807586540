<template>
  <div class="fixed-column rounded" :class="{ 'mb-75': windowWidth < 1000 }">
    <b-card class="m-0 border-secondary" body-class="p-0 py-2 px-50">
      <h5 class="text-center">
        {{ getName }}
      </h5>
      <hr />
      <div class="d-flex justify-content-around align-items-center">
        <h5 class="m-0">Bakiye:</h5>
        <span class="fs-16">{{ getBalance }} </span>
      </div>
      <hr />
      <div class="d-flex justify-content-around align-items-center">
        <h5 class="m-0">Gecikme Faizi:</h5>
        <span class="fs-16">{{ getOverdueInterest }} </span>
      </div>
      <hr />
      <b-row class="m-0">
        <b-col md="6">
          <app-button @click="showPaymentForm" text="Tahsilat Ekle" size="md" block />
        </b-col>
        <b-col md="6">
          <app-dropdown offset="0" block>
            <template #button-content>
              <app-button text="Ekstre" size="md" block />
            </template>
            <b-dropdown-item @click="showTxPdfRangeForm"> PDF İndir </b-dropdown-item>
            <b-dropdown-item @click="showSendEmailTxPdfForm">E-Posta Gönder </b-dropdown-item>
          </app-dropdown>
        </b-col>
      </b-row>
      <hr />
      <div class="d-flex flex-column gap-10">
        <note-collapse />
        <contact-peoples />
        <contact-emails />
      </div>
    </b-card>
  </div>
</template>

<script>
import ContactNotes from "../ContactNotes/ContactNotes.vue";
import NoteCollapse from "./NoteCollapse.vue";
import ContactPaymentForm from "../../ContactPaymentForm.vue";
import TxPdfRangeFormVue from "./TxPdfRangeForm.vue";
import SendEmailTxPdfForm from "./SendEmailTxPdfForm.vue";
import ContactPeoples from "../ContactPeople/ContactPeoples.vue";
import ContactEmails from "../ContactEmails/ContactEmails.vue";
export default {
  components: { NoteCollapse, ContactPeoples, ContactEmails },
  data() {
    return {
      collapses: [{ title: "Notlar", component: ContactNotes }],
    };
  },
  computed: {
    item() {
      return this.$store.getters?.selectedParasutContact;
    },
    getName() {
      return this?.item?.name;
    },
    getBalance() {
      return this.$formatToTL(this?.item?.balance);
    },
    getOverdueInterest() {
      return this.$formatToTL(this?.item?.total_overdue_interest);
    },
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  methods: {
    showPaymentForm() {
      this.$showAppSidebar("Tahsilat Ekle", ContactPaymentForm, { data: { ...this.item } });
    },
    showTxPdfRangeForm() {
      this.$showAppModal({ title: "Ekstre Tarih Aralığı", component: TxPdfRangeFormVue, size: "md", item: { data: { ...this.item } } });
    },
    showSendEmailTxPdfForm() {
      this.$showAppSidebar("Ekstreyi E-Posta'ya Gönder", SendEmailTxPdfForm, { data: { ...this.item } });
    },
  },
};
</script>

<style>
.fixed-column {
  position: sticky;
  top: 65px;
  max-height: calc(100vh - 125px);
  overflow-y: auto;
}
</style>
