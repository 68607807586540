<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="sendEmailTxPdfForm">
      <b-form-group class="" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          class="d-flex justify-content-center align-items-center flex-column gap-20"
          v-model="selected"
          :options="options"
          :aria-describedby="ariaDescribedby"
        ></b-form-radio-group>
      </b-form-group>
      <div v-if="selected">
        <hr />
        <app-date-input v-model="dateFrom" :max-date="new Date().setHours(0, 0, 0, 0)" name="Başlangıç Tarihi" label="Başlangıç Tarihi:" placeholder="Tarih Seçiniz..." />
        <app-date-input v-model="dateTo" :max-date="new Date().setHours(0, 0, 0, 0)" name="Bitiş Tarihi" label="Bitiş Tarihi:" placeholder="Tarih Seçiniz..." />
      </div>
      <hr />
      <contact-email-auto-suggest-input v-model="to" :contact_id="item.contact_id" />
      <app-input v-model="subject" name="Konu:" label="Konu" placeholder="Konu (İsteğe Bağlı)" />
      <app-textarea-input v-model="mailContent" name="Açıklama" label="Açıklama:" placeholder="Açıklama (İsteğe Bağlı)" />
      <app-button @click="send" text="Gönder" size="md" block />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ContactEmailAutoSuggestInput from "@/views/Finance/InvoicePayments/ContactEmailAutoSuggestInput.vue";
export default {
  components: { ValidationObserver, ContactEmailAutoSuggestInput },
  data() {
    return {
      dateTo: this.getOneMonthDateRange().to,
      dateFrom: this.getOneMonthDateRange().from,
      selected: false,
      options: [
        { text: "Tüm işlemler", value: false },
        { text: "Belirli tarih aralığındaki işlemler", value: true },
      ],
      subject: "",
      to: "",
      mailContent: "",
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appSidebarItem?.data;
    },
  },
  methods: {
    setData() {
      return {
        to: this.to,
        subject: this.subject,
        mailContent: this.mailContent,
        dateTo: this.selected ? this.dateTo : null,
        dateFrom: this.selected ? this.dateFrom : null,
      };
    },
    getOneMonthDateRange() {
      const date = new Date();
      const year = date.getFullYear();
      const day = String(date.getDate()).padStart(2, "0");

      const lastMonth = String(date.getMonth()).padStart(2, "0");
      const thisMonth = String(date.getMonth() + 1).padStart(2, "0");
      return {
        from: `${year}-${lastMonth}-${day}`,
        to: `${year}-${thisMonth}-${day}`,
      };
    },
    send() {
      this.$validate(this.$refs.sendEmailTxPdfForm, () => {
        this.$axios
          .post(`/finance/contacts/${this?.item?.contact_id}/tx-history-pdf/send-to-email`, this.setData(), { loading: "sidebar" })
          .then(() => {
            this.$emitter.$emit("Notification", { title: "İşlem Başarılı", variant: "success" });
            this.$store.commit("cleanAppSidebar");
          })
          .catch((err) => {
            console.log(err);
            this.$emitter.$emit("Notification", { title: "Hata Oluştu", variant: "danger", message: err?.data?.message });
          });
      });
    },
  },
};
</script>

<style></style>
