<template>
  <b-card body-class="p-0" class="m-0">
    <app-button @click="showContactForm" class="flex-1-md mb-1" text="Kişi Ekle" icon="PlusIcon" size="md" block />
    <b-alert variant="warning" :show="showAlert" fade>
      <div class="alert-body">
        <feather-icon class="mr-50" icon="InfoIcon" />
        <span>Henüz kişi eklenmemiştir. </span>
      </div>
    </b-alert>
    <b-card v-for="item in peoples" :key="item._id" body-class="p-1 d-flex align-items-center justify-content-between flex-wrap gap-10" class="border-secondary mb-50">
      <div class="d-flex align-items-center gap-15">
        <mdi-icon icon="Phone"></mdi-icon>
        <div>
          <h5 class="mb-25">{{ item.fullname }}</h5>
          <span>
            <div>({{ formatPhoneNumber(item.phone) }})</div>
          </span>
          <footer v-if="item && item.duty" class="blockquote-footer">
            <small class="text-muted">
              <cite title="Source Title">{{ item.duty }}</cite>
            </small>
          </footer>
        </div>
      </div>
      <div class="d-flex justify-content-end flex-1">
        <app-dropdown boundary="scrollParent">
          <b-dropdown-item @click="showContactForm(item)"> <feather-icon icon="EditIcon" class="mr-25" /> Güncelle </b-dropdown-item>
          <b-dropdown-item @click="deletePeople(item)"> <feather-icon icon="TrashIcon" class="mr-25" /> Kaldır </b-dropdown-item>
        </app-dropdown>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import CompanyContactForm from "@/views/Companies/details/info/contacts/CompanyContactForm.vue";
import { formatPhoneNumber } from "@/global-prototypes";

export default {
  data() {
    return {
      showAlert: false,
      peoples: [],
    };
  },
  computed: {
    item() {
      return this.$store.getters.selectedParasutContact;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
  },
  watch: {
    peoples() {
      this.showAlert = Boolean(!this.peoples.length);
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatPhoneNumber,
    getData() {
      if (this.selectedCompany?.parasut_contact_id || this.item?.contact_id) {
        this.$emit("loading", true);
        this.$axios
          .get("/company/contacts/all", { params: { parasut_contact_id: this.selectedCompany?.parasut_contact_id ?? this.item?.contact_id } })
          .then((result) => {
            this.peoples = result.data;
            this.$emit("loading", false);
          })
          .catch((err) => {
            this.$emit("loading", false);
            console.log(err);
          });
      } else this.peoples = [];
    },
    deletePeople(item) {
      this.$confirm({ message: "Kişi silinecektir emin misiniz" }, () => {
        this.$axios
          .delete(`/company/contacts/${item?._id}`)
          .then(() => {
            this.getData();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    showContactForm(item) {
      this.$showAppSidebar(item ? "Güncelle" : "Kişi Ekle", CompanyContactForm, { updateItem: item ? { ...item } : null, callback: this.getData });
    },
  },
};
</script>

<style></style>
