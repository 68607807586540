<template>
  <b-row>
    <b-col :class="{ 'pl-0': windowWidth > 1000 }" order-xl="2" order-lg="2" lg="4" xl="3">
      <contact-info />
    </b-col>
    <b-col order-xl="1" order-lg="1" lg="8" xl="9">
      <b-custom-tabs id="contact-detail-tabs" :tabs="tabs" lazy />
      <!--  <b-card :body-class="windowWidth > 768 ? 'p-1' : 'p-0 py-1'"> </b-card> -->
    </b-col>
  </b-row>
</template>

<script>
import ContactInfo from "./ContactInfo/ContactInfo.vue";
import ContactTransactions from "./ContactTransactions.vue";
import Invoices from "../../invoices/Invoices.vue";
import InvoicePaymentTabs from "../../InvoicePayments/InvoicePaymentTabs.vue";

export default {
  props: ["id"],
  components: { ContactInfo },
  data() {
    return {
      tabs: [
        { title: "Açık Faturalar", component: Invoices },
        { title: "Hareketler", component: ContactTransactions },
        { title: "Tahsilatlar", component: InvoicePaymentTabs },
        { title: "Raporlar" },
      ],
    };
  },
  computed: {
    windowWidth() {
      return this.$store.getters["app/windowWidth"];
    },
  },
  destroyed() {
    this.$route.name !== "finance-contact-detail" && this.$store.commit("setSelectedParasutContact", null);
  },
};
</script>

<style></style>
