<template>
  <app-overlay loading-variable="modalOverlay">
    <b-form-group v-slot="{ ariaDescribedby }">
      <b-form-radio-group v-model="selected" :options="options" :aria-describedby="ariaDescribedby"></b-form-radio-group>
    </b-form-group>
    <div v-if="selected">
      <hr />
      <app-date-input v-model="from" :max-date="new Date().setHours(0, 0, 0, 0)" name="Başlangıç Tarihi" label="Başlangıç Tarihi:" placeholder="Tarih Seçiniz..." />
      <app-date-input v-model="to" :max-date="new Date().setHours(0, 0, 0, 0)" name="Bitiş Tarihi" label="Bitiş Tarihi:" placeholder="Tarih Seçiniz..." />
    </div>
    <hr />
    <app-button @click="downloadTxPdf" text="İndir" icon="DownloadCloudIcon" size="md" block />
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      from: this.getOneMonthDateRange().from,
      to: this.getOneMonthDateRange().to,
      selected: false,
      options: [
        { text: "Tüm işlemler", value: false },
        { text: "Belirli tarih aralığındaki işlemler", value: true },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters?.appModalItem?.data;
    },
  },
  methods: {
    downloadTxPdf() {
      this.$axios
        .get(`/finance/contacts/${this?.item?.contact_id}/tx-history-pdf`, {
          params: { from: this.selected ? this.from : null, to: this.selected ? this.to : null },
          loading: "modal",
        })
        .then((result) => {
          if (result?.data?.url) window.open(result?.data?.url, "_blank");
          this.$store.commit("cleanAppModal");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getOneMonthDateRange() {
      const date = new Date();
      const year = date.getFullYear();
      const day = String(date.getDate()).padStart(2, "0");

      const lastMonth = String(date.getMonth()).padStart(2, "0");
      const thisMonth = String(date.getMonth() + 1).padStart(2, "0");
      return {
        from: `${year}-${lastMonth}-${day}`,
        to: `${year}-${thisMonth}-${day}`,
      };
    },
  },
};
</script>

<style></style>
